@import "../../styles//mainColors";

.InstallLandingDiv{

    width: 100%;
}

.title{

    width: 100%;
    font-size: 1.4rem;
    background-color: $menuBgColor ;
    padding: 8px 1rem;
    color: #fff;
    border-radius: 8px;
    user-select: none;
    margin-bottom: 3rem;

    display: flex;
    align-items: center;
    justify-content: space-between;


    i{

        display: inline-flex;
        font-size: 2rem;
    }
}


.consts{

    margin-bottom: 2rem;
    border-bottom: 2px dashed #eee;
    padding-bottom: 3rem;

}


.titleTopAccordion{


    font-size: 1.3rem;
    margin: 0 0 1rem;

}



.bodyOfAccordion{

    display: grid;
    grid-template-columns: repeat(2 , 1fr);
}



.ParaDiv{

    display: flex;
    flex-direction: column;
    background-color: rgb(241, 241, 241);
    margin: 1rem;
    padding: 0rem 1rem 1rem;
    border-radius: 8px;
    
    .toptext{

        display: flex;
        flex-direction: column;
        margin : 20px 0 10px;

    }

    .ParaName{

        font-size: 1.3rem;
        margin-bottom: 0.5rem;

    }

    .paraDec{

        font-size: 1rem;
    }



    input{

        height: 40px;
        padding: 0.5rem 10px;
        border-radius: 5px;
        outline: none;
        border : none;
        background-color: #ffffff;
        transition: all 0.2s;

        &:focus , &:hover{

            box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.07) 0px 0px 8px;
        }

    }


    input[type="color"] {

        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        background: none;
        border: 0;
        cursor: pointer;
        padding: 0;
        width: 40px ;
      
        padding: 0 !important;
        background-color: none !important;
      
      
      }

      input[type="checkbox"] {

        cursor: pointer;
        

        &:focus , &:hover{

            box-shadow: none;
        }
      
      }



}

.SelectPanel{

    cursor: pointer;

}
.userContainer{


    width: 100%;
}

.TopOfTable{

    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: space-between;

    .leftTop{

        display: flex;
        align-items: center;
    }

    button{

        display: flex;
        align-items: center;

        i{

            display: inline-flex;
           
        }
    }

    .InfoCountTable{

        font-size: 0.9rem;
        margin-right: 5px ;
        margin-bottom: -5px;
        color: #696969;
    }
}


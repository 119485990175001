.DivShowLandings{

    width: 100%;
}

.TopOfTable{

    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;

    

    .InfoCountTable{

        font-size: 0.9rem;
        color: #696969;
        margin-bottom: -5px;
    }
}

@font-face {
  font-family: iransans-normal;
  src: url('./assets/fonts/IRANSansWeb_Medium.eot');
  src: url('./assets/fonts/IRANSansWeb_Medium.eot') format('embedded-opentype'),
    url('./assets/fonts/IRANSansWeb\(FaNum\).woff') format('woff'),
    url('./assets/fonts/IRANSansWeb_Medium.woff2') format('woff2')
}




@import "./styles/_animate.module.scss";



* {

  direction: rtl;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}


body {

  font-family: iransans-normal;
  background-color: #f9f9f9;
}

a {

  text-decoration: none;
}


*::-webkit-scrollbar {
  width: 16px;
}

*::-webkit-scrollbar-track {
  background-color: #e4e4e4;
  border-radius: 100px;
}

*::-webkit-scrollbar-thumb {
  background-color: #c4c4c4;
  border-radius: 100px;
}


.accordion-button {

  font-size: 1.1rem;
}

.accordion-button::after {

  margin-right: 5px !important;

}

.ant-select-selection-overflow {

  height: 40px !important;
}

.ant-select-selector {
  width: 100%;
  height: fit-content !important;
  padding: 6px 11px !important;
  background-color: #eeeeee !important;
  border: none !important;
}


@import "../../styles/mainColors";
@import "../../styles/animate.module";

.DetailTemplateDiv {

    width: 100%;
}


.Top {

    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    background-color: #e9e5f6be;
    border-radius: 8px;


    padding: 1rem 1.5rem;


}

.rightTop {

    display: flex;
    flex-direction: column;
    align-items: center;


    .nameSlug {

        display: flex;
        flex-direction: column;
    }

    .titleTemp {

        margin-bottom: 10px;
        background-color: $menuBgColor;
        padding: 5px 10px;
        color: #fff;
        font-size: 1.6rem;
        border-radius: 5px;
    }

    .slugTemp {

        margin-right: 10px;
        font-size: 0.9rem;
    }

    .CounterInstall{

        margin-top: 3rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #fff;
        background-color: #4C516D;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
        padding: 1rem 1.5rem;
        border-radius: 5px;


        span:nth-child(1){

            font-size: 1.2rem;
        }

        span:nth-child(2){

            font-size: 1.3rem;
        }
    }
   

}


.leftTop {


    display: flex;
    align-items: flex-start;


    .datesDiv {

        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .dates {

        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: 1rem;
        font-size: 0.75rem;
        background-color: #f9f9f9d5;
        padding: 5px 10px;
        border-radius: 5px;
    }

    .status {

        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 3rem;
        user-select: none;

        i {

            display: inline-flex;
            font-size: 2rem;
            color: #fff;
            padding: 0.7rem;
            border-radius: 100px;
            box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
        }

        span{

            margin-top: 10px;
        }
    }



    .ImgTemplate {

        width: 150px;
        height: auto !important;
        margin-right: 10px;
        border-radius: 5px;
        box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
        animation: tada 18s infinite forwards;
    }



}


.center {

    


}

.decripDiv{

    border : dashed 2px  #adadad5e ; 
    border-radius: 5px;
    padding: 1rem;
    margin-top: 2rem;

    span{

        background-color: #4C516D;
        color: #fff;
        width: 100%;
        padding: 5px 1rem;
        border-radius: 5px;
        user-select: none;
        
    }


    p{

        line-height: 1.7rem;
        padding: 1rem;
        text-align: justify;
        margin-top: 1rem;
    }

}

.backBtn{

    margin-top: 10rem;
}
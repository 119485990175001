.DetailStatisticsDiv{

    width: 100%;
}

.content{

    width: 100%;
    background-color: #ecececc7;
    padding: 1rem;
    border-radius: 8px;

    display: flex;
    flex-direction: column;


    span{

        margin: 1rem 0;
    }

}
.notfoundDiv{

    width: 100%;

    display: flex;
    align-items: center;
    height:100vh;
    justify-content: center;
}

.not{

    display: flex;
    flex-direction: column;
    align-items: center;

    img{

        width: 500px;
        border-radius: 50%;
        
    }

    span{

        margin-top: 2rem;
        font-size: 1.5rem;
        user-select: none;
    }
}
@import "../styles/mainColors";


.myDash {

    display: flex;


}



.menuDash {


    // width: 16%;
    height: 100vh;
    z-index: 10;
    background-color: #fff;
    overflow: auto;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: all 0.5s;

    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;




    .logoDiv {

        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 1.5rem 1.5rem 1.5rem 1rem;
        margin-bottom: 2rem;





        .logoLandingMaker {

            width: 60px;
            height: auto !important;
            // pointer-events: none;
            background-color: #e9e5f6;
            border-radius: 50%;
            padding: 5px;
        }



        .textLogo {

            font-size: 1.1rem;
            font-weight: bold;
            margin-right: 10px;
            user-select: none;
        }

    }


    .menuUl {

        width: 100%;

        ul {


            display: flex;
            flex-direction: column;
            align-items: center;
            list-style-type: none;
            padding: 0;

            li {

                width: 100%;
                display: flex;
                justify-content: center;
                margin : 0.2rem 0;

            }


            a {

                width: calc(100% - 2rem);
                padding: 0.8rem 2.5rem 0.8rem 1rem;
                border-radius: 15px;
                cursor: pointer;
                transition: all 0.25s, color 0s;

                color: #98979d;

                display: flex;
                align-items: center;

                &:hover {

                    color: #fff;
                    background-color: $menuBgColor;

                }

                &:hover span {

                    transform: translateX(-10px);
                }

                &:hover i {


                    background-color: rgba(255, 255, 255, 0.11);

                }


            }

            i {

                font-size: 1.6rem;
                display: inline-flex;
                transition: initial;

                padding: 7px;
                border-radius: 100px;


            }

            span {

                font-size: 1.1rem;
                margin-right: 1rem;
                user-select: none;
                transition: inherit;
                padding-top: 3px;

            }



        }
    }



}






.contentDash {

    // width: 84%;
    height: 100vh;
    background-color: #f9fafc;



    .mainContent {

        width: 100%;
        height: calc(100% - 100px);
        overflow: auto;
        padding: 1rem;

    }
}


.HeaderDash {


    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;

    width: 100%;
    height: 100px;
    background-color: #f9fafc;

    // border-bottom: 1px solid #98979d;

    .rtightHeader {

        display: flex;
        align-items: center;

    }

    .searchDiv {


        background-color: rgb(241, 241, 241);
        display: flex;
        align-items: center;
        padding: 0.6rem 15px;
        border-radius: 50px;
        transition: all 0.1s;



        input {

            width: 300px;
            border: none;
            outline: none;
            background-color: transparent;


            &::placeholder {

                font-size: 0.9rem;
                color: #b6b6b6;
            }



        }

        i {

            display: inline-flex;
            cursor: pointer;
            transition: all 0.2s;
            padding: 0 0.5rem;

            &:hover {

                color: $menuBgColor;
            }


        }



    }

    .searchDiv:has(input:focus) {

        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    }

    .headerIcons {

        font-size: 1.5rem;
        color: #a8a8a8;
        cursor: pointer;
        display: inline-flex;
        padding: 0.5rem;
        margin: 0 0.5rem;
        transition: all 0.2s;


        &:hover {

            color: $menuBgColor ;
            background-color: #e9e5f6;
            border-radius: 100px;
        }
    }

    #hamburgerMenu {

        color: $menuBgColor ;
        background-color: #e9e5f6;
        border-radius: 100px;

        &:hover {

            transform: translateX(3px);
            background-color: #ddd6f5;
        }
    }



    .leftHeader {

        display: flex;
        align-items: center;
        
    }

    .divPicPro {

        width: fit-content;
        margin-right: 1.5rem;
        margin-left: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        cursor: pointer;
        position: relative;


        &:hover #arrowDown {

            color: $menuBgColor;
            background-color: #e9e5f6;
        }

        #arrowDown {

            display: inline-flex;
            padding: 5px 5px 3px;
            border-radius: 50px;
            color: #7c7c7c;
            font-weight: bold;
            font-size: 0.8rem;
            margin-left: 0.1rem;
            transition: all 0.2s;

        }

        .picProfile {

            width: 75px;
            height: 75px;
            border-radius: 100px;
            user-select: none;

        }

        .userInfo{

            margin-left: 0.6rem;
            user-select: none;
            font-size: 0.95rem;

        }

        .dropDownProfile{

            
            position: absolute;
            top: 60px;
            right: 0;
            width: 150px;
            height: fit-content;
            background-color: rgb(255, 255, 255);
            box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
            border-radius: 8px;
            padding: 5px 0 0px;

           
        }

        .ulDropDown{

            list-style-type: none;
            padding: 0;
            width: 100%;

            
            
            li{

                width: 100% !important;
                transition: all 0.2s , color 0s;
                border-radius: 5px;
                color: #333333;

                &:hover{

                    background-color: $menuBgColor;
                    color: #fff;
                    padding-right: 5px;
                    
                }

                &:last-child:hover{

                    background-color: #e3242b;
                }
            }

           a{
                width: 100%;
                display: block;
                color: inherit;
                
                display: flex;
                align-items: center;
                padding: 8px 15px;
                
               
                
            }

            i{

                display: inline-flex;
                margin-left: 8px;
            }
            
        }
    }





}

.active {


    color: #fff !important;
    background-color: $menuBgColor;

    i {


        background-color: rgba(255, 255, 255, 0.11);

    }

   

}

.btnSearch{

    border: none;
    outline: none;
    
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px 5px;
    margin-right: 3px;
    position: relative;
    right: 10px;
   

    i{

        display: inline-flex !important;
        // margin: 5px 0 0 !important;
    }
}
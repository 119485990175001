@import "../../styles/mainColors";

.EditeUserDiv{

    width: 100%;
}

.title{

    width: 100%;
    font-size: 1.4rem;
    background-color: $menuBgColor ;
    padding: 8px 1rem;
    color: #fff;
    border-radius: 8px;
    user-select: none;
    margin-bottom: 3rem;

    display: flex;
    align-items: center;
    justify-content: space-between;


    i{

        display: inline-flex;
        font-size: 2rem;
    }
}

.InputsDiv{

    margin: 1rem 0.5rem 4rem;
}
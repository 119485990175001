@import "../styles/mainColors";

.showNothinDiv{

    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 206px);


    div{

        display: flex;
        align-items: center;
        justify-content: center;

        width: 450px;
        height: 250px;
        border-radius: 20px;

        background-color: $mainBgTemp ;


    }


    span{

        font-size: 1.2rem;
        color: #a8a8a8;
        user-select: none;
    }
}
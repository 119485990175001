@import "../../styles/mainColors";
@import "../../styles/animate.module";

.rolePageDiv{

    width: 100%;
}


.cards{

    display: grid;
    grid-template-columns: repeat(auto-fit , minmax(auto , 300px));
    justify-content: space-around;
    align-content: center;
}



.card{

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem 1rem 1.5rem;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
    background-color: #fff;
    border-radius: 8px;
    margin: 1rem;
    transition: all 0.2s;
    user-select: none;
    


    .actionBtns{

        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;

        .editeIcon , .deleteIcon{
           
            cursor: pointer;
            transition: all 0.2s;
            border: $mainBgTemp 3px solid;
            padding: 5px;
            border-radius: 50px;
        }

        .editeIcon:hover ,  .deleteIcon:hover{

            transform: rotate(20deg)
        }

        .deleteIcon{

            color: #e3242b;
        }

        .editeIcon{

            color: $menuBgColor;
        }
    }

    .cardImage{

        width: 60px;
        height: 60px;
        margin: 1rem 0 1.5rem;
    }


    .cardName {

        font-size: 0.95rem;
        margin-bottom: 1.5rem;
        background-color: $mainBgTemp ;
        padding: 0.5rem 1.2rem;
        border-radius: 5px;
        
    }

   
    
}

.divCon{

    position: relative;
    width: 100%;
    

    display: flex;
    justify-content: center;

    .dastresi{

        font-size: 0.7rem;
        border: 1px solid #eee;
        padding: 0.3rem 1rem;
        cursor : pointer;
        transition: all 0.2s;
        border-radius: 8px;

        &:hover{

            box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
        }
    }

    .permissionsAll{

        display: none;
        flex-wrap: wrap;
        margin-top: 0.5rem;
        background-color: #e15c63ae;
        border-radius: 5px;
        padding: 1rem;
        width: 100%;
    
        position: absolute;
        left: 0;
        top: 30px;
        z-index: 1100;

        animation: fadeIn 0.15s;
        
    
        
    
    
        .permission{
    
           background-color: #fff;
           border-radius: 5px;
           padding: 0.15rem 0.5rem;
           margin: 0.3rem 0.2rem;
           font-size: 0.56rem;
          
        }
    
        span{
    
    
        }
    }

}







@import "../../styles/mainColors";
.DivEditeRole{


    width: 100%;
}

.ShowInfo{

    display: flex;
    flex-direction: column;
    align-items: center;

    img{

        width: 75px;
        height: 75px;
        margin-bottom: 1rem;
    }

    span{

        background-color: $mainBgTemp;
        padding: 5px 10px;
        border-radius: 5px;

    }
}
@import  "../../styles/animate.module";

.trashDiv{

    width: 100%;
}


.topOfTrash{

    display: flex;
    justify-content: center;
    margin-bottom: 2rem;

    i{

        animation: zoomIn 0.3s;
        font-size: 4rem;
        color: #fff;
        background-color: #eb7575;
        padding: 1rem;
        display: inline-flex;
        border-radius: 10px;

    }
}

.screenimage{

    width: 110px;
    height: auto !important;
    margin:  0 1rem 1rem;
    border-radius: 5px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    user-select: none;
    
}

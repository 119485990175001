@import "../../styles/mainColors";


.DetailLandingDiv {

    width: 100%;
}


.infoTop {


    .iconDiv {

        display: flex;
        justify-content: center;


        i {

            color: #fff;
            background-color: $menuBgColor ;
            display: inline-flex;
            padding: 1rem;
            border-radius: 100px;
            font-size: 3rem;
        }
    }


    .textsInfo {

        display: flex;
        flex-direction: column;
        align-items: center;

        margin-top: 10px;


        .title {

            font-size: 1.2rem;
            margin: 0.8rem 0 1rem;

        }

        .slug {

            font-size: 0.9rem;
        }

    }


}


.iconDown {

    display: flex;
    justify-content: center;
    margin: 1rem 0;

    i {

        font-size: 1.2rem;
        color: #939393;
    }

}


.infoCenter {

    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, auto));
    margin: 0rem 0 1rem;

}


.cardLanding {

    background-color: $mainBgTemp;
    margin: 0 0.8rem;
    border-radius: 1rem;
    padding: 2rem;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}


.card2 {





    .nameTitle {

        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 2.5rem;

        background-color: $menuBgColor;
        color: #fff;
        padding: 0.5rem 1rem;
        border-radius: 8px;


        span {

            margin: 0.2rem 0;
        }

    }


    .createUpdate {

        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 0.8rem;

        span {

            margin: 0.2rem;
        }
    }
}


.card1 {

    user-select: none;

    span {

        display: block;
        color: initial;
       
    }

    span:nth-child(1) {

        font-size: 2.5rem;
        border: 1px solid $menuBgColor;
        width: 80px;
        height: 80px;
        border-radius: 100px;
        margin-bottom: 1rem;


        display: flex;
        justify-content: center;
        align-items: center;

        transition: all 0.2s;

        &:hover{

            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        }
    }
}


.card3 {

    user-select: none;

    .onlineDiv {

        color: #fff;
        margin-bottom: 1rem;

        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.glow {
    font-size: 3rem;
    color: #fff;
    text-align: center;
    -webkit-animation: glow 0.7s ease-in-out infinite alternate;
    -moz-animation: glow 0.7s ease-in-out infinite alternate;
    animation: glow 0.7s ease-in-out infinite alternate;
}


.btns {

    margin-top: 3.5rem;
}



@keyframes glow {
    from {
        text-shadow: 0 0 15px #03ac13, 0 0 25px #03ac13, 0 0 35px #03ac13, 0 0 45px #03ac13, 0 0 55px #03ac13, 0 0 65px #03ac13, 0 0 75px #03ac13;
    }

    to {
        text-shadow: 0 0 25px #4ee758, 0 0 35px #4ee758, 0 0 45px #4ee758, 0 0 55px #4ee758, 0 0 65px #4ee758, 0 0 75px #4ee758, 0 0 85px #4ee758;
    }
}

@-webkit-keyframes glow {
    from {
        text-shadow: 0 0 15px #03ac13, 0 0 25px #03ac13, 0 0 35px #03ac13, 0 0 45px #03ac13, 0 0 55px #03ac13, 0 0 65px #03ac13, 0 0 75px #03ac13;
    }

    to {
        text-shadow: 0 0 25px #4ee758, 0 0 35px #4ee758, 0 0 45px #4ee758, 0 0 55px #4ee758, 0 0 65px #4ee758, 0 0 75px #4ee758, 0 0 85px #4ee758;
    }
}
@import "../styles/mainColors" ;

.divNewUser{

    margin: 0 1rem;

}

.Inputs{

    display: flex;
    flex-direction: column;
    margin: 0 0 1.2rem;


    label{

        margin-bottom: 8px;
    }

    

    input , textarea{

        // direction: ltr;
        text-align: right;
        color: #333333;
        width: 100%;
        border: none;
        outline: none;
        height: 40px;
        background-color: #eee;
        padding: 5px 8px;
        border-radius: 4px;
        transition: all 0.2s;

        &:focus{

            box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
        }
    }


    textarea{

      height: auto;
    }
}


.Divchecks{

    margin : 2.5rem 0 0.5rem ;

    .checks{


        display: flex;
        align-items: center;
        justify-content: space-evenly;
    }



}

.genderDiv{

  display: flex;
  margin : 3rem 0;

}


/* Customize the label (the container) */
.conCehcks , .conRadio {
    display: block;
    position: relative;
    padding-left: 28px;
    // margin-bottom: 12px;
    cursor: pointer;
    font-size: 1.1rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }


  .conRadio{

    margin: 0 0.5rem;
    margin-right: 1rem;
    font-size: 1rem;
  }


  /* Hide the browser's default checkbox */
  .conCehcks input , .conRadio input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }



  
  /* Create a custom checkbox */
  .checkmark , .checkmark2 {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #eee;
    border-radius: 2px;
  }

  .checkmark2 {
    
    border-radius: 50%;
  }

  
  /* On mouse-over, add a grey background color */
  .conCehcks:hover input ~ .checkmark , .conRadio:hover input ~ .checkmark2 {
    background-color: #ccc;
  }


  
  /* When the checkbox is checked, add a blue background */
  .conCehcks input:checked ~ .checkmark , .conRadio input:checked ~ .checkmark2 {
    background-color: $menuBgColor;
  }



  
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after , .checkmark2:after {
    content: "";
    position: absolute;
    display: none;
  }


  
  /* Show the checkmark when checked */
  .conCehcks input:checked ~ .checkmark:after , .conRadio input:checked ~ .checkmark2:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .conCehcks .checkmark:after {
    left: 7px;
    top: 3px;
    width: 7px;
    height: 12px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }


  .conRadio .checkmark2:after {
    top: 6px;
    left: 6px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
  }
  


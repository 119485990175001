@import "../../styles/mainColors";
@import "../../styles/animate.module";


.installTemplateDiv {

    width: 100%;
}

.Divchecks {

    margin: 3.5rem 0 0.5rem;

    .checks {


        display: flex;
        align-items: center;
        justify-content: space-evenly;
    }

}

.btns {

    display: flex;
    justify-content: flex-start;
    margin-top: 5rem;
}




.containerParameter {}


.Groups {

    background-color: #f0f0f060;
    animation:  zoomIn 0.35s;

    input{

        padding: 0 5px;
        
        text-align: right;

    }

    select {

        background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>") no-repeat;
        background-position: calc(100% - 0.75rem) center !important;
        background-color: #fff;
        -moz-appearance: none !important;
        -webkit-appearance: none !important;
        appearance: none !important;
        padding-right: 2rem !important;
        cursor: pointer;
    }



}

.groupNameDiv {

    display: flex;
    align-items: center;


    .btnPlusPara,
    .btnDeleteGroup {

        font-size: 0.7rem;
        display: flex;
        align-items: center;
        margin-right: 5px;
    }

    i {

        display: inline-flex;
    }

}


.myParameterOfGroup {

    animation:  fadeIn 0.4s;
    margin: 1rem 1rem;
    background-color: $mainBgTemp;
    padding: 1rem;
    border-radius: 5px;

    position: relative;

    .deletePara {

        display: inline-flex;
        color: #e3242b;
        position: absolute;
        left: -4px;
        top: -8px;
        cursor: pointer;
        transition: all 0.1s;

        &:hover {

            transform: scale(1.1, 1.1);
        }
    }

}



.nameParaDiv {

    width: 100%;
    display: flex;
    align-items: center;


    .namePara {}



}



.keyandValueDiv {

    display: flex;
    align-items: center;


    .myKey {

        display: flex;
        align-items: center;
        align-items: center;
        margin: 1rem 0;

        width: 50%;
    }

    select {

        width: 100%;
    }

    input {

        width: 100%;
    }





}



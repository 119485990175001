.DivShowTemplate{

    width: 100%;
}

.TopOfTable{

    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;

    

    .InfoCountTable{

        font-size: 0.9rem;
        color: #696969;
        margin-bottom: -5px;
    }
}

.screenimage{

    width: 110px;
    height: auto !important;
    margin:  0 1rem 1rem;
    border-radius: 5px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    user-select: none;
    
}

.EditeLanding{

    width: 100%;
}

.SelectPanel{

    cursor: pointer;

}

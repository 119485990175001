.ProfileDiv{

    width: 100%;
}

.PicProfile{

    display: flex;
    align-items: center;
    justify-content: center;
    img {

        border: 2px solid #333333;
        border-radius: 100px;
        padding: 5px;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
    }
}


.btns{

    margin : 4rem 0 1rem;
}

.changePass{

    margin: 4rem 0 1rem;
}

.btnChangePass{

    display: flex;
    align-items: center;
    justify-content: flex-end;
}
@import "../styles/animate.module";


.contentContainer {


    width: 100%;
    min-height: 100%;
    background-color: rgb(255, 255, 255);
    border-radius: 20px;
    padding: 1rem 1.5rem 1.5rem;




    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 5px 0px;


}


.ContentBody {

    height: initial;
    width: initial;
}

.titlePage {

    display: flex;
    justify-content: flex-start;
    text-align: center;

    span {

        background-color: #e9e5f6f4;
        border-radius: 5px;

        animation: zoomIn 0.4s;

        padding: 0.4rem 1rem;
        font-size: 0.9rem;
        user-select: none;
        color: #757575;
        position: relative;
        top: -30px;
        right: 10px;


    }

}
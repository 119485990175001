@import "../styles/mainColors";


.mainCon {

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;


    .loginCon {

        width: 500px;
        height: fit-content;
        background-color: rgb(255, 255, 255);
        border-radius: 10px;
        position: relative;
        transition: 0.2s all;

        box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;

        padding: 1.5rem;

        .loginCon>form , .loginCon>div {

            display: flex;
            flex-direction: column;
        }

    }


}


.loading{

    background-color: rgba(255, 255, 255, 0.897) ;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    top :0;
    right: 0;

    position: absolute;
    width: 100%;
    height: 100%;
}


.loginIcon {


    width: 83px;
    pointer-events: none;
    user-select: none;
    height: auto !important;
    margin: 1rem 0 1.5rem;
}

.back{

    width: 500px;
    display: flex;
    justify-content: start;

    span{

        color: #bcbcbc;
        cursor: pointer;
        user-select: none;
        font-size: 0.9rem;
        margin-bottom: 0.5rem;
        margin-right: 0.5rem;
        transition: 0.2s all;

        &:hover{

            color: #606060;
        }
    }
}

.InputDiv {

    display: flex;
    flex-direction: column;
    justify-content: start;

    label {

        margin-bottom: 10px;
        font-size: 1rem;
        user-select: none;
    }

    input {

        direction: ltr;
        text-align: center;
        font-size: 1.1rem;
        letter-spacing: 3px;
        width: 100%;
        border: none;
        height: 40px;
        outline: none;
        background-color: #f2f2f2;
        transition: all 0.2s;

        padding: 0 1rem;

        border-radius: 10px;

        &:focus {

            box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
        }

        &::placeholder {

            font-size: 1.2rem;
            letter-spacing: 5px;
            color: rgb(181, 181, 181);
        }

    }
}

.buttons {

    display: flex;
    align-items: center;
    justify-content: center;

    margin-top: 2rem;

    button {

        padding: 5px 1rem;
        margin: 0 1rem;
        user-select: none;

        border-radius: 5px;

        border: none;
        color: #fff;
        background-color: $backButtons;
        transition: all 0.25s;

        &:hover {

            background-color: $hoverButtons;
        }
    }
}

.forgetPass {

    margin: 1rem 0;
    font-size: 0.9rem;
    color: #c0c0c0;
    cursor: pointer;
    padding: 2px 8px;
    border-radius: 100px;

    transition: all 0.2s;
    user-select: none;

    &:hover {

        background-color: #fff;
        color: #797979;
    }
}

.showNumber{

    font-size: 0.8rem;
    margin : 0.5rem 1rem 2rem 0 ;
    color: $backButtons;
    user-select: none;
}


@media(max-width : 768px) {

    .mainCon {


        .loginCon {

            width: 400px;


        }


    }

    .back{

        width: 400px;
        
    }

    .buttons {


        button {


            font-size: 0.9rem;

        }
    }

    .loginIcon {


        width: 80px;

    }

}


@media(max-width : 450px) {

    .mainCon {


        .loginCon {

            width: calc(100% - 1.5rem);
            padding: 1rem;


        }


    }

    .back{

        width: calc(100% - 1.5rem);
        
        span{
    
        
            font-size: 0.8rem;
            
        }
    }

    .buttons {

        flex-direction: column;

        margin-top: 1rem;

        button {

            width: 100%;
            padding: 5px 1rem;
            margin: 0.5rem 1rem;
            font-size: 1rem;
        }
    }

    .loginIcon {


        width: 60px;

    }

    .forgetPass {


        font-size: 0.8rem;

    }


}